.landing {
  width: 490px;
  margin: 0 auto;
  padding: 100px 0;
}
@media (max-width: 600px) {
  .landing {
    width: 90vw;
    padding: 60px 0;
  }
}
.landing h1,
.landing h2 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}
.landing .sun {
  margin-bottom: 85px;
  position: relative;
  z-index: 999;
  display: block;
  transition: color 2.9s;
  cursor: pointer;
}
.landing .sun.is-colored {
  color: #80d0f3;
}
.landing .sun:before {
  font-size: 40px;
  vertical-align: middle;
  display: inline-block;
  animation: sunUp 2s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
/*firefox never plays well with rendering fonts!! this centers the sun*/
@-moz-document url-prefix() {
  .landing .sun:before {
    margin-top: -2px;
  }
}
.landing .sun .text-small {
  opacity: 0;
  margin: 0;
  animation: fadeIn 2s 1.5s forwards;
}
.landing .text {
  margin-bottom: 57px;
  line-height: 1.4;
}
.landing .text:nth-child(3) {
  margin-bottom: 93px;
}
@media (max-width: 600px) {
  .landing .text {
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
  .landing .text:nth-child(3) {
    margin-bottom: 50px;
  }
}
@media (max-width: 320px) {
  .landing .text {
    font-size: 1.35rem;
  }
}
.landing ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.landing p {
  margin: 0;
}
.landing .apps ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 93px;
}
.landing .apps__item {
  width: 120px;
  font-size: 16px;
}
.landing .app-icon {
  width: 120px;
  height: 120px;
  margin: 0 0 25px 0;
  overflow: hidden;
}
.landing .app-icon img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  .landing .apps ul {
    margin-bottom: 50px;
  }
  .landing .apps__item {
    width: 25vw;
  }
  .landing .app-cta {
    font-size: 14px;
  }
  .landing .app-icon {
    width: 25vw;
    height: 25vw;
  }
}
@media (max-width: 320px) {
  .landing .apps__item {
    font-size: 0.9rem;
  }
  .landing .app-cta {
    font-size: 12px;
  }
}
.landing .app-title {
  margin-bottom: 10px;
}
.landing .app-cta {
  opacity: 0.5;
  position: relative;
}
.landing .apps__item:nth-child(1) svg {
  transform: rotate(-135deg);
}
.landing .app-cta svg {
  vertical-align: middle;
}
.landing .text-medium {
  font-size: 1.5rem;
  line-height: 1.333333333;
}
@media (max-width: 600px) {
  .landing .text-medium {
    font-size: 1.1rem;
  }
}
@media (max-width: 320px) {
  .landing .text-medium {
    font-size: 1rem;
  }
}
.landing .text-medium span {
  opacity: 0.5;
}
.landing__footer {
  margin-top: 80px;
  line-height: 1.5;
}
.landing__footer span {
  opacity: 0.5;
}
@media (max-width: 600px) {
  .landing__footer {
    font-size: 0.9rem;
  }
}
@media (max-width: 320px) {
  .landing__footer {
    font-size: 0.8rem;
  }
}
.landing a {
  transition: opacity 0.5s;
}
.landing a:hover {
  opacity: 0.7;
}
.landing-transition {
  background: #031320;
  font-size: 16px;
}
.landing-transition body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #031320;
  animation: bgTransition 3s 2s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}
.landing .text,
.text-medium,
.apps,
.landing__footer {
  opacity: 0;
  animation: fadeIn 2.3s 2.55s forwards;
}
.theme-sunrise .landing path {
  fill: #ea5700;
}
.theme-daylight .landing path,
.theme-sunset .landing path {
  fill: #ab4621;
}
.theme-twilight .landing path {
  fill: #3c57a0;
}
.theme-night .landing path {
  fill: #80d0f3;
}
@-moz-keyframes bgTransition {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes bgTransition {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes bgTransition {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bgTransition {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes sunUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes sunUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes sunUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes sunUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@-moz-keyframes toColor {
  0% {
    color: #80d0f3;
  }
  100% {
    color: inherit;
  }
}
@-webkit-keyframes toColor {
  0% {
    color: #80d0f3;
  }
  100% {
    color: inherit;
  }
}
@-o-keyframes toColor {
  0% {
    color: #80d0f3;
  }
  100% {
    color: inherit;
  }
}
@keyframes toColor {
  0% {
    color: #80d0f3;
  }
  100% {
    color: inherit;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
